/* Blow.css */

/* Header section animation */
.page-header {
    background-color: #343a40; /* Dark background */
    color: #fff;
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .animated-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .animated-image:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
  }
  
  /* Responsive grid styling */
  @media (max-width: 768px) {
    .image-container {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .breadcrumb-item a,
    .breadcrumb-item.active {
      font-size: 0.9rem;
    }
  }
  