.header-top {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: rgb(255, 255, 255) !important;
}

.header-images {
  display: flex;
  justify-content: space-between;
  width: 110%;
  max-width: 900px; /* Limit the maximum width for better alignment */
}

.header-image {
  height: 14vh;
  object-fit: contain;
  /* Additional margin to control spacing */
  margin: 0 15px;
}

@media (max-width: 768px) {
  .header-images {
    justify-content: center;
  }
  .header-image {
    margin: 0 10px;
    width: 10vh !important;
  }
}