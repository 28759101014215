@media (max-width: 768px) {
   
  
    .footer .logo img {
      max-width: 180px;
      height: auto;
      margin-left: 5vh;
    }
  
    .footer .btn-link {
      font-size: 14px;
    
    }
  }
  
  