/* Pvc.css */

/* Header section background color */
.page-header {
    background-color: #343a40;
    color: #fff;
  }
  
  /* Image container styling */
  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .animated-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .animated-image:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
  }
  
  /* Responsive adjustments for grid */
  @media (max-width: 992px) {
    .col-lg-3 {
      flex: 0 0 33.3333%; /* 3 columns on tablets */
      max-width: 33.3333%;
    }
  }
  
  @media (max-width: 768px) {
    .col-md-4 {
      flex: 0 0 50%; /* 2 columns on smaller devices */
      max-width: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .col-sm-6 {
      flex: 0 0 100%; /* 1 column on extra-small screens */
      max-width: 100%;
    }
  
    .breadcrumb-item a,
    .breadcrumb-item.active {
      font-size: 0.9rem;
    }
  }
  