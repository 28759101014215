/* General Styles */
.page-header {
    background-color: #343a40;
    color: #fff;
}

.container h2, .container h3 {
    color: #333;
}

/* Image Styling */
.img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


/* Advanced Card Styling */
.spec-card {
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
    height: 100%;
    text-align: left; /* Ensure text aligns properly */
}

.spec-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Card Header Styling */
.spec-card h4 {
    font-size: 1.25rem;  /* Increased font size for better visibility */
    color: #343a40;
    margin-bottom: 10px;
    font-weight: 600;
    position: relative;
}

.spec-card h4::before {
    content: '';
    width: 40px;
    height: 4px;
    background-color: #17a2b8;
    position: absolute;
    bottom: -6px;
    left: 0;
}

/* Card Content Styling */
.spec-card p, .spec-card ul {
    color: #555;  /* Slightly darker color for better contrast */
    font-size: 1rem; /* Increase font size for better legibility */
    line-height: 1.6; /* Increase line-height for readability */
}

.spec-card ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;

}

.spec-card li::before {
    
    color: #17a2b8;
    font-weight: bold;
    margin-right: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .spec-card {
        margin-bottom: 20px;
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .spec-card h4 {
        font-size: 1.15rem; /* Adjust heading size for smaller screens */
    }

    .spec-card p, .spec-card ul {
        font-size: 0.95rem; /* Adjust font size for readability */
    }
}
