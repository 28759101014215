/* Timeline Section Styling */
.timeline {
    list-style-type: none;
    padding-left: 0;
    position: relative;
    margin: 0;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #17a2b8;
    transform: translateX(-50%);
}

.timeline li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.timeline li:last-child {
    margin-bottom: 0;
}

.timeline li .year {
    position: absolute;
    right:90%;
    top: 15px;
    transform: translateX(-50%);
    background-color: #17a2b8;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline li p {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-left: 80px;
    width: calc(100% - 80px); /* Adjust for the year circle */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.timeline li p::before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: 30px;
    height: 100%;
    background-color: #17a2b8;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Styling */
@media (max-width: 768px) {
    .timeline {
        padding-left: 20px;
    }

    .timeline::before {
        left: 20px;
        transform: translateX(0);
        width: 2px;
    }

    .timeline li {
        padding-left: 50px;
    }

    .timeline li .year {
        left: 15px;
        top: -5px;
        width: 40px;
        height: 40px;
        font-size: 14px;
    }

    .timeline li p {
        margin-left: 60px;
        width: calc(100% - 60px);
    }
}

@media (max-width: 480px) {
    .timeline li {
        padding-left: 40px;
    }

    .timeline li p {
        margin-left: 50px;
        width: calc(100% - 50px);
    }

    .timeline li .year {
        width: 35px;
        height: 35px;
        font-size: 12px;
    }
}
