/* For larger screens */
@media (min-width: 1200px) {
    .container-xxl {
        width: 100%;
    }
    .service-item {
        padding: 30px;
    }
    .service-item h4 {
        font-size: 1.25rem;
    }
    .btn-square {
        width: 64px;
        height: 64px;
    }
}

/* For medium screens (laptops, tablets) */
@media (max-width: 1199px) {
    .container-xxl {
        width: 90%;
        margin: 0 auto;
    }
    .service-item {
        padding: 25px;
    }
    .service-item h4 {
        font-size: 1.1rem;
    }
    .btn-square {
        width: 50px;
        height: 50px;
    }
}

/* For small screens (tablets and below) */
@media (max-width: 768px) {
    .container-xxl {
        width: 100%;
    }
    .service-item {
        padding: 20px;
    }
    .service-item h4 {
        font-size: 1rem;
    }
    .btn-square {
        width: 45px;
        height: 45px;
    }
    /* Stack the services vertically for smaller screens */
    .service-row {
        flex-direction: column;
    }
    .col-md-6.col-lg-3 {
        width: 100%;
        margin-bottom: 15px;
    }
}

/* For very small screens (phones) */
@media (max-width: 480px) {
    .service-item {
        padding: 15px;
    }
    .service-item h4 {
        font-size: 1rem;
    }
    .btn-square {
        width: 40px;
        height: 40px;
    }
    .container-xxl {
        padding-left: 10px;
        padding-right: 10px;
    }
}
